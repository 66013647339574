import axios from '@axios'

export default {

  fetchAll(context, page = 1) {
    return axios.get(`shipments?page=${page}`)
  },

  get(context, id) {
    return axios.get(`shipments/${id}`)
  },

  create(context, data) {
    return axios.post('shipments', data)
  },

  import(context, data) {
    return axios.post('shipments/import', data)
  },

  remove(context, id) {
    return axios.delete(`shipments/${id}`)
  },

  update(context, data) {
    return axios.put(`shipments/${data.id}`, data)
  },
}
