export default [
  {
    path: '/configurations',
    name: 'configurations',
    component: () => import('@/views/pages/configurations/ConfigurationsList.vue'),
    meta: {
      pageTitle: 'Configurations',
      resource: 'Page',
      breadcrumb: [
        {
          text: 'Configurations',
          active: true,
        },
      ],

      buttonAction: [{
        title: 'Add new configuration',
        route: 'add-configuration',
        icon: 'PlusCircleIcon',
      }],
    },
  },
  {
    path: '/configurations/add',
    name: 'add-configuration',
    component: () => import('@/views/pages/configurations/AddConfiguration.vue'),
    meta: {
      pageTitle: 'Configurations',
      resource: 'Page',
      breadcrumb: [
        {
          text: 'Configurations',
          active: true,
        },
      ],
    },
  },
  {
    path: '/configurations/edit/:configurationId',
    name: 'edit-configuration',
    component: () => import('@/views/pages/configurations/EditConfiguration.vue'),
    meta: {
      pageTitle: 'Configurations',
      resource: 'Page',
      breadcrumb: [
        {
          text: 'Configurations',
          active: true,
        },
      ],
    },
  },
]
