export default [
  {
    path: '/documents-columns/template/:templateId',
    name: 'documents-columns',
    component: () => import('@/views/pages/documents-columns/ColumnsList.vue'),
    meta: {
      pageTitle: 'Templates',
      resource: 'Page',
      breadcrumb: [
        {
          text: 'Templates',
          active: true,
        },
      ],
    },
  },
  {
    path: '/documents-columns/edit/:columnId',
    name: 'edit-column',
    component: () => import('@/views/pages/documents-columns/EditColumn.vue'),
    meta: {
      pageTitle: 'Columns',
      resource: 'Page',
      breadcrumb: [
        {
          text: 'Columns',
          active: true,
        },
      ],
    },
  },
  {
    path: '/documents-templates',
    name: 'documents-templates',
    component: () => import('@/views/pages/documents-templates/TemplatesList.vue'),
    meta: {
      pageTitle: 'Templates',
      resource: 'Page',
      breadcrumb: [
        {
          text: 'Templates',
          active: true,
        },
      ],

      buttonAction: [{
        title: 'Add new template',
        route: 'add-template',
        icon: 'PlusCircleIcon',
      }],
    },
  },
  {
    path: '/documents-templates/add',
    name: 'add-template',
    component: () => import('@/views/pages/documents-templates/AddTemplate.vue'),
    meta: {
      pageTitle: 'Templates',
      resource: 'Page',
      breadcrumb: [
        {
          text: 'Templates',
          active: true,
        },
      ],
    },
  },
  {
    path: '/documents-templates/edit/:templateId',
    name: 'edit-template',
    component: () => import('@/views/pages/documents-templates/EditTemplate.vue'),
    meta: {
      pageTitle: 'Templates',
      resource: 'Page',
      breadcrumb: [
        {
          text: 'Templates',
          active: true,
        },
      ],
    },
  },
  {
    path: '/documents',
    name: 'documents',
    component: () => import('@/views/pages/documents/DocumentsList.vue'),
    meta: {
      pageTitle: 'Documents',
      resource: 'Page',
      breadcrumb: [
        {
          text: 'Documents',
          active: true,
        },
      ],
    },
  },

]
