import axios from '@axios'

export default {
  namespaced: true,
  //   state,
  //   mutations,
  actions: {

    fetchAll(context, page = 1) {
      return axios.get(`notifications?page=${page}`)
    },

    get(context, id) {
      return axios.get(`notifications/${id}`)
    },

    remove(context, id) {
      return axios.delete(`notifications/${id}`)
    },

  },
//   getters
}
