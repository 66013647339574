import axios from '@axios'

export default {

  fetchAll(context, page = 1) {
    return axios.get(`amz-ads/accounts?page=${page}`)
  },

  get(context, id) {
    return axios.get(`amz-ads/accounts/${id}`)
  },

  create(context, data) {
    return axios.post('amz-ads/accounts', data)
  },

  import(context, data) {
    return axios.post('amz-ads/accounts/import', data)
  },

  remove(context, id) {
    return axios.delete(`amz-ads/accounts/${id}`)
  },

  update(context, data) {
    return axios.put(`amz-ads/accounts/${data.id}`, data)
  },

  fetchLogs(context, params) {
    let query = `?page=${params.page || 1}`

    if (params.key) {
      query += `&key=${params.key}`
    }

    if (params.status) {
      query += `&status=${params.status}`
    }

    return axios.get(`amz-ads/accounts/${params.accountId}/logs${query}`)
  },
}
