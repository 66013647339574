export default [
  {
    path: '/search-terms/logs',
    name: 'search-terms-logs',
    component: () => import('@/views/pages/search-terms-reports/SearchTermsLogs.vue'),
    meta: {
      pageTitle: 'Search Terms Logs ',
      resource: 'Page',
      breadcrumb: [
        {
          text: 'Search Terms',
          active: true,
        },
      ],
      buttonAction: [
      ],
    },
  },
  {
    path: '/competitors-asins/add',
    name: 'add-competitor-asin',
    component: () => import('@/views/pages/search-terms-reports/AddCompetitorsAsin.vue'),
    meta: {
      pageTitle: 'Competitors Asins',
      resource: 'Page',
      breadcrumb: [
        {
          text: 'Add Competitor Asin',
          active: true,
        },
      ],
    },
  },
]
