import axios from '@axios'

export default {
  namespaced: true,
  //   state,
  //   mutations,
  actions: {

    fetchAll(context, page = 1) {
      return axios.get(`app-settings?page=${page}`)
    },

    get(context, id) {
      return axios.get(`app-settings/${id}`)
    },

    getByKey(context, key) {
      return axios.get(`app-settings/key/${key}`)
    },

    updateByKey(context, params) {
      return axios.put(`app-settings/key/${params.key}`, params.value)
    },

  },
//   getters
}
