import axios from '@axios'

export default {
  namespaced: true,
  //   state,
  //   mutations,
  actions: {

    fetchAll(context, page = 1) {
      return axios.get(`document-templates?page=${page}`)
    },

    get(context, id) {
      return axios.get(`document-templates/${id}`)
    },

    create(context, data) {
      return axios.post('document-templates', data)
    },

    remove(context, id) {
      return axios.delete(`document-templates/${id}`)
    },

    update(context, data) {
      return axios.put(`document-templates/${data.id}`, data)
    },

  },
//   getters
}
