import Vue from 'vue'
import FeatherIcon from '@core/components/feather-icon/FeatherIcon.vue'

Vue.filter('formatDate', value => {
  if (value) {
    const formatting = { month: 'numeric', day: 'numeric', year: 'numeric' }
    return new Intl.DateTimeFormat('en-US', formatting).format(new Date(value))
  }
})

Vue.filter('formatDateTime', (value, timeZone = 'PST') => {
  if (value) {
    const formatting = {
      month: 'numeric',
      day: 'numeric',
      year: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
      second: 'numeric',
      hour12: false,
      timeZone,
    }
    return `${new Intl.DateTimeFormat('en-US', formatting).format(new Date(value))} ${timeZone}`
  }
})

Vue.filter('formatDate', (value, timeZone = 'PST') => {
  if (value) {
    const formatting = {
      month: 'numeric',
      day: 'numeric',
      year: 'numeric',
      hour12: false,
      timeZone,
    }
    return `${new Intl.DateTimeFormat('en-US', formatting).format(new Date(value))} ${timeZone}`
  }
})


Vue.component(FeatherIcon.name, FeatherIcon)
