import axios from '@axios'

export default {
  namespaced: true,
  //   state,
  //   mutations,
  actions: {

    forgot(context, data) {
      return axios.post('auth/forgot', data)
    },

    reset(context, data) {
      return axios.post('auth/reset', data)
    },

  },

}
