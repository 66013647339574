import axios from '@axios'

export default {
  namespaced: true,
  //   state,
  //   mutations,
  actions: {

    fetchAll(context, params) {
      let query = `?page=${params.page || 1}`

      if (params.accountId) {
        query += `&accountId=${params.accountId}`
      }

      return axios.get(`amz-reports-range-requests${query}`)
    },

    get(context, id) {
      return axios.get(`amz-reports-range-requests/${id}`)
    },

    create(context, data) {
      return axios.post('amz-reports-range-requests', data)
    },

    remove(context, id) {
      return axios.delete(`amz-reports-range-requests/${id}`)
    },

    cancel(context, id) {
      return axios.put(`amz-reports-range-requests/${id}/cancel`)
    },

    update(context, data) {
      return axios.put(`amz-reports-range-requests/${data.id}`, data)
    },

  },
//   getters
}
