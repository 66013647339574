import axios from '@axios'

export default {
  namespaced: true,
  //   state,
  //   mutations,
  actions: {

    fetchAll(context, params) {
      let query = `?page=${params.page || 1}`

      if (params.templateId) {
        query += `&templateId=${params.templateId}`
      }

      return axios.get(`document-columns${query}`)
    },

    get(context, id) {
      return axios.get(`document-columns/${id}`)
    },

    create(context, data) {
      return axios.post('document-columns', data)
    },

    remove(context, id) {
      return axios.delete(`document-columns/${id}`)
    },

    update(context, data) {
      return axios.put(`document-columns/${data.id}`, data)
    },

  },
//   getters
}
