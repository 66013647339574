import Vue from 'vue'
import Vuex from 'vuex'

// Modules
import app from './app'
import appConfig from './app-config'
import appSetting from './app-setting/moduleSetting'
import verticalMenu from './vertical-menu'
import shipmentModule from './shipment/moduleShipment'
import moduleConfiguration from './configuration/moduleConfiguration'
import moduleAwsSellingPartnerAccount from './amz-selling-partner-account/moduleAmzSellingPartnerAccount'
import moduleAmzAds from './amz-ads/moduleAmzAds'
import moduleNotification from './notification/moduleNotification'
import moduleCompetitorsAsin from './competitior-asin/moduleCompetitorsAsin'
import moduleDocument from './document/moduleDocument'
import moduleDocumentTemplate from './document-template/moduleDocumentTemplate'
import moduleDocumentColumn from './document-column/moduleDocumentColumn'
import moduleAmzReportsRange from './amz-reports-range/moduleAmzReportsRange'
import moduleAmzRangeRequest from './amz-range-request/moduleAmzRangeRequest'
import moduleBigQuery from './bigquery/moduleBigQuery'
import moduleAuth from './auth/moduleAuth'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    app,
    appSetting,
    appConfig,
    verticalMenu,
    shipment: shipmentModule,
    configuration: moduleConfiguration,
    amzSellingPartnerAccount: moduleAwsSellingPartnerAccount,
    amzAds: moduleAmzAds,
    notification: moduleNotification,
    competitorAsin: moduleCompetitorsAsin,
    document: moduleDocument,
    documentTemplate: moduleDocumentTemplate,
    documentColumn: moduleDocumentColumn,
    amzReportsRange: moduleAmzReportsRange,
    amzRangeRequest: moduleAmzRangeRequest,
    bigQuery: moduleBigQuery,
    auth: moduleAuth,
  },
  strict: process.env.DEV,
})
