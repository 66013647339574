import Vue from 'vue'
import VueRouter from 'vue-router'
import { canNavigate } from '@/libs/acl/routeProtection'
import { isUserLoggedIn, getUserData, getHomeRouteForLoggedInUser } from '@/auth/utils'
import shipmentsRoutes from './routes/shipments'
import configurationsRoutes from './routes/configurations'
import amzAdsRoutes from './routes/amz-ads'
import amzSellingPartnersRoutes from './routes/amz-selling-partners'
import searchTermsRoutes from './routes/search-terms'
import documentsRoutes from './routes/documents'

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
  routes: [
    {
      path: '/',
      name: 'home',
      component: () => import('@/views/pages/Home.vue'),
      meta: {
        pageTitle: 'Home',
        resource: 'Home',
        action: 'read',
        breadcrumb: [
          {
            text: 'Home',
            active: true,
          },
        ],
      },
    },
    ...shipmentsRoutes,
    ...configurationsRoutes,
    ...amzAdsRoutes,
    ...amzSellingPartnersRoutes,
    ...searchTermsRoutes,
    ...documentsRoutes,
    {
      path: '/notifications',
      name: 'notifications',
      component: () => import('@/views/pages/notifications/NotificationsList.vue'),
      meta: {
        pageTitle: 'Notifications',
        resource: 'Page',
        breadcrumb: [
          {
            text: 'Notifications',
            active: true,
          },
        ],
      },
    },
    {
      path: '/skuvault/quantities',
      name: 'skauvault-quantities',
      component: () => import('@/views/pages/skuvault/SkuvaultQuantities.vue'),
      meta: {
        pageTitle: 'Skuvault Quantities',
        resource: 'Page',
        breadcrumb: [
          {
            text: 'Quantities',
            active: true,
          },
        ],
      },
    },
    {
      path: '/tools/bigquery',
      name: 'tools-bigquery',
      component: () => import('@/views/pages/tools/BigQyeryRows.vue'),
      meta: {
        pageTitle: 'Check bigQuery row',
        resource: 'Page',
        breadcrumb: [
          {
            text: 'Check bigQuery row',
            active: true,
          },
        ],
      },
    },
    {
      path: '/auth/login',
      name: 'login',
      component: () => import('@/views/pages/authentication/Login.vue'),
      meta: {
        resource: 'Auth',
        layout: 'full',
        // redirectIfLoggedIn: true,
      },
    },
    {
      path: '/auth/forgot',
      name: 'forgot',
      component: () => import('@/views/pages/authentication/ForgotPassword.vue'),
      meta: {
        resource: 'Auth',
        layout: 'full',
        // redirectIfLoggedIn: true,
      },
    },
    {
      path: '/auth/reset/:token',
      name: 'reset',
      component: () => import('@/views/pages/authentication/ResetPassword.vue'),
      meta: {
        resource: 'Auth',
        layout: 'full',
        // redirectIfLoggedIn: true,
      },
    },
    {
      path: '/error-404',
      name: 'error-404',
      component: () => import('@/views/error/Error404.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '/not-authorized',
      name: 'not-authorized',
      component: () => import('@/views/pages/NotAuthorized.vue'),
      meta: {
        layout: 'full',
        resource: 'Auth',
      },
    },
    {
      path: '*',
      redirect: 'error-404',
    },
  ],
})

router.beforeEach((to, _, next) => {
  const isLoggedIn = isUserLoggedIn()

  if (!canNavigate(to)) {
    if (isLoggedIn !== true) {
      // Redirect to login if not logged in
      return next({ name: 'login' })
    }
    // If logged in => not authorized
    return next({ name: 'not-authorized' })
  }

  // Redirect if logged in
  if (to.meta.redirectIfLoggedIn && isLoggedIn === true) {
    const userData = getUserData()

    next(getHomeRouteForLoggedInUser(userData ? userData.role : null))
  }

  return next()
})

// ? For splash screen
// Remove afterEach hook if you are not using splash screen
router.afterEach(() => {
  // Remove initial loading
  const appLoading = document.getElementById('loading-bg')
  if (appLoading) {
    appLoading.style.display = 'none'
  }
})

export default router
