// import state from './moduleAcContactState.js'
// import mutations from './moduleAcContactMutations.js.js'
import actions from './moduleShipmentActions.js'
// import getters from './moduleAcContactGetters.js'

export default {
  namespaced: true,
  //   state,
  //   mutations,
  actions,
//   getters
}
