export default [
  {
    path: '/shipments',
    name: 'shipments',
    component: () => import('@/views/pages/shipments/ShipmentsList.vue'),
    meta: {
      pageTitle: 'Shipments',
      resource: 'Page',
      breadcrumb: [
        {
          text: 'Shipments',
          active: true,
        },
      ],

      buttonAction: [{
        title: 'Add',
        route: 'add-shipment',
        icon: 'PlusCircleIcon',
      },
      {
        title: 'Import',
        route: 'import-shipments',
        icon: 'FileIcon',
      }],
    },
  },
  {
    path: '/shipments/edit/:shipmentId',
    name: 'edit-shipment',
    component: () => import('@/views/pages/shipments/EditShipment.vue'),
    meta: {
      pageTitle: 'Edit Shipment',
      resource: 'Page',
      breadcrumb: [
        {
          text: 'Shipments',
          active: true,
        },
      ],
    },
  },
  {
    path: '/shipments/add',
    name: 'add-shipment',
    component: () => import('@/views/pages/shipments/AddShipment.vue'),
    meta: {
      pageTitle: 'Shipments',
      resource: 'Page',
      breadcrumb: [
        {
          text: 'Shipments',
          active: true,
        },
      ],
    },
  },
  {
    path: '/shipments/import',
    name: 'import-shipments',
    component: () => import('@/views/pages/shipments/ImportShipments.vue'),
    meta: {
      pageTitle: 'Shipments',
      resource: 'Page',
      breadcrumb: [
        {
          text: 'Shipments',
          active: true,
        },
      ],
    },
  },
]
