export default [
  {
    path: '/amz-ads-accounts',
    name: 'amz-ads-accounts',
    component: () => import('@/views/pages/amz-ads/AdsAccountsList.vue'),
    meta: {
      pageTitle: 'AMZ ADS ACCOUNTS',
      resource: 'Page',
      breadcrumb: [
        {
          text: 'Amz Ads Accounts',
          active: true,
        },
      ],
    },
  },
  {
    path: '/amz-ads-accounts/edit/:accountId',
    name: 'edit-amz-ads-account',
    component: () => import('@/views/pages/amz-ads/AdsAccountEdit.vue'),
    meta: {
      pageTitle: 'AMZ ADS ACCOUNTS',
      resource: 'Page',
      breadcrumb: [
        {
          text: 'Accounts',
          active: true,
        },
      ],
    },
  },
  {
    path: '/amz-ads-accounts/logs/:accountId',
    name: 'amz-ads-logs',
    component: () => import('@/views/pages/amz-ads/AdsAccountLogs.vue'),
    meta: {
      pageTitle: 'AMZ ADS LOGS',
      resource: 'Page',
      breadcrumb: [
        {
          text: 'Ads Account Logs',
          active: true,
        },
      ],
    },
  },
  {
    path: '/search-terms',
    name: 'search-terms',
    component: () => import('@/views/pages/search-terms-reports/SearchTermsHome'),
    meta: {
      pageTitle: 'Search Terms Home ',
      resource: 'Page',
      breadcrumb: [
        {
          text: 'Search Terms',
          active: true,
        },
      ],

      buttonAction: [{
        title: 'Add new asin',
        route: 'add-competitor-asin',
        icon: 'PlusCircleIcon',
      },
      {
        title: 'Show logs',
        route: 'search-terms-logs',
        icon: 'ListIcon',
      },
      ],
    },
  },
]
