import axios from '@axios'

export default {

  fetchAll(context, page = 1) {
    return axios.get(`configurations?page=${page}`)
  },

  get(context, id) {
    return axios.get(`configurations/${id}`)
  },

  create(context, data) {
    return axios.post('configurations', data)
  },

  remove(context, id) {
    return axios.delete(`configurations/${id}`)
  },

  update(context, data) {
    return axios.put(`configurations/${data.id}`, data)
  },
}
