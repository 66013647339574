import axios from '@axios'

export default {
  namespaced: true,
  //   state,
  //   mutations,
  actions: {

    getRows(context, params) {

      let query = `?page=1`
  
      if (params.table) {
        query += `&table=${params.table}`
      }
  
      if (params.dataset) {
        query += `&dataset=${params.dataset}`
      }

       
      if (params.columns) {
        query += `&columns=${params.columns}`
      }

      if (params.whereColumn) {
        query += `&whereColumn=${params.whereColumn}`
      }

      if (params.orderBy) {
        query += `&orderBy=${params.orderBy}`
      }
       
      if (params.value) {
        query += `&value=${params.value}`
      }
  
      return axios.get(`bigquery/rows/${query}`)
    },

  },
//   getters
}
